<template>
  <div class="admin-wizard">
    <AdminHeader />
    <b-container>
      <!-- Breadcrumb -->
      <b-row class="admin-breadcrumb">
        <b-col>
          <b-breadcrumb>
            <b-breadcrumb-item href="#">
              <router-link to="dashboard">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Return to Dashboard</router-link
              >
            </b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
      </b-row>
      <main class="admin-quick-start">
        <!-- Intro -->
        <section>
          <b-row class="align-items-center">
            <b-col lg="6">
              <h1>Welcome to your <br />Quick Start Guide.</h1>
              <h5>
                This page introduces you to the WGAW Candidate Website Builder's
                most important features you need to know to set up your website.
                While this doesn't cover everything, it does cover the basics
                and it’s a good place to start after logging into your account
                for the first time.
                <span class="font-weight-bold">Let's get started...</span>
              </h5>
            </b-col>
            <b-col lg="5" offset-lg="1">
              <b-img class="illustration"
                     alt="Welcome"
                     :src="
                  require('@/assets/images/admin/quickstart/undraw-product_tour.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="topics">
                <b-row>
                  <b-col sm="1">
                    <span class="font-weight-bold">Topics: </span>
                  </b-col>
                  <b-col>
                    <a href="#dashboard">Getting familiar with the Dashboard</a>
                    <span class="pipe">|</span>
                    <a href="#wizard">Navigating the wizard</a>
                    <span class="pipe">|</span>
                    <a href="#theme">Customizing your Candidate Website</a>
                    <span class="pipe">|</span>
                    <a href="#endorsements">Managing your Endorsements</a>
                    <span class="pipe">|</span>
                    <a href="#support">Contacting support</a>
                  </b-col>
                </b-row>                
              </div>
            </b-col>
          </b-row>
        </section>
        <hr />
        <!-- Dashboard -->
        <section id="dashboard">
          <b-row>
            <b-col>
              <h3>Getting familiar with the Dashboard.</h3>
              <p class="mb-5">
                On the Dashboard landing page, you will see an overview of your
                <span class="font-weight-bold">Website Completion Progress</span>. If the section is completed, the circle checkmark will be
                green. If the section is not completed, your circle checkmark
                will be gray. You do not have to complete every section,
                required sections will have a red asterisk
                <span class="asterisk">*</span>. You can click on the
                <span class="font-weight-bold">Continue</span> button to get
                started or continue where you left off. Once you have completed
                the required sections, you will be able to
                <span class="font-weight-bold">Preview</span> or
                <span class="font-weight-bold">Publish</span> your website.
              </p>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col lg="10">
              <b-img class="screenshot"
                     alt="Dashboard"
                     :src="
                  require('@/assets/images/admin/quickstart/screen-dashboard.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
        </section>
        <hr />
        <!-- Wizard -->
        <section id="wizard">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h3>Navigating the wizard.</h3>
              <p>
                On the wizard layout, you will have a sidebar on the left that
                will act as your section navigation and progress overview. The
                section you are currently on will be highlighted blue. If the
                section is completed, it will be marked in green. You can click
                <span class="font-weight-bold">Back</span> to go to the previous
                section you were on, click
                <span class="font-weight-bold">Skip</span> to move ahead (you
                can come back to uncompleted sections anytime), and click
                <span class="font-weight-bold">Save & Continue</span> to save
                your progress and go to the next seciton.
              </p>
              <p>
                You will also notice the
                <span class="font-weight-bold">Preview</span> and
                <span class="font-weight-bold">Publish</span> buttons on the top
                right that you can click anytime to review or publish your
                changes to be live.
              </p>
              <p class="mb-5">
                And finally, look out for the tip bar underneath each section
                heading. The tip bar will act as a visual guide and display an
                example of how your content will appear on the website, click
                the <span class="font-weight-bold">See Example</span> button to
                launch the tip guide in each section.
              </p>
            </b-col>
            <b-col lg="5" offset-lg="1">
              <b-img class="illustration"
                     alt="Wizard"
                     :src="
                  require('@/assets/images/admin/quickstart/undraw-setup_wizard.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-5">
            <b-col lg="10">
              <b-img class="screenshot"
                     alt="Dashboard"
                     :src="
                  require('@/assets/images/admin/quickstart/screen-wizard.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
        </section>
        <hr />
        <!-- Theme -->
        <section id="theme">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h3>Customizing your Candidate Website.</h3>
              <p>
                Choosing a theme is one of the first steps in setting up your
                Canddiate Website. The theme will create the look and feel of
                your entire website. We recommend previewing the theme by
                clicking the
                <span class="font-weight-bold">Preview</span> button underneath
                each theme thumbnail, you can get an overview of how your
                content will be presented before making your selection.
              </p>
              <p class="mb-5">
                After selecting the theme, you will be able to click
                <span class="font-weight-bold">Preview</span> on the top right
                to see how your content will appear in the selected theme.
              </p>
            </b-col>
            <b-col lg="5" offset-lg="1">
              <b-img class="illustration"
                     alt="Theme"
                     :src="
                  require('@/assets/images/admin/quickstart/undraw-building_websites.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-5">
            <b-col lg="10">
              <b-img class="screenshot"
                     alt="Dashboard"
                     :src="
                  require('@/assets/images/admin/quickstart/screen-theme.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
        </section>
        <hr />
        <!-- Endorsements -->
        <section id="endorsements">
          <b-row class="align-items-center">
            <b-col lg="8">
              <h3>Managing your Endorsements</h3>
              <p>
                Your first step in setting up your site to collect endorsements will be to provide your non-candidate statement. Please be sure to send the text to the Election Administrator to verify that it is in compliance prior to adding it to your site. Once you have published your statement members will be able to endorse your candidacy.
              </p>
              <p class="mb-5">
                In your candidate admin you will now see a section titled <span class="font-weight-bold">Manage Endorsements</span>. From here you can control what endorsements show on your site as well as control how they will be displayed. Simply mark an endorsement as <span class="font-weight-bold">Featured</span> and you will activate the featured endorsements tool. From here you select how you would like your endorsement to be displayed, <span class="font-weight-bold">Left Aligned</span>, or <span class="font-weight-bold">Center Aligned</span>. Next you can create groups and sort your featured endorsements within those groups. The final step it to preview your featured endorsements so that you can confirm they are displaying as you expected.
              </p>
            </b-col>
            <b-col lg="3" offset-lg="1">
              <b-img class="illustration"
                     alt="Managing Endorsements"
                     :src="
                  require('@/assets/images/admin/quickstart/undraw_content_structure.svg')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-5">
            <b-col lg="10">
              <b-img class="screenshot"
                     alt="Dashboard"
                     :src="
                  require('@/assets/images/admin/quickstart/screen-endorsements.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
        </section>
        <hr />
        <!-- Support -->
        <section id="support">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h3>Contacting support.</h3>
              <p class="mb-5">
                On the top header of every page, you will find the
                <span class="font-weight-bold">Help</span> link. You can click
                this at any time to contact Thinklogic, our third-party vendor,
                with any technical questions or issues you are experiencing. The
                Support Team will respond between 8:00am and 6:00pm (PST) from
                Monday to Friday.
              </p>
            </b-col>
            <b-col lg="5" offset-lg="1">
              <b-img class="illustration"
                     alt="Support"
                     :src="
                  require('@/assets/images/admin/quickstart/undraw-remote_team.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
          <b-row class="justify-content-center mt-5">
            <b-col lg="10">
              <b-img class="screenshot"
                     alt="Dashboard"
                     :src="
                  require('@/assets/images/admin/quickstart/screen-support.png')
                "
                     fluid></b-img>
            </b-col>
          </b-row>
        </section>
        <hr />
      </main>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Quick Start"
};
</script>

<style lang="scss">
@import "@/assets/styles/admin/_base.scss";

html {
  scroll-behavior: smooth;
}

.admin-breadcrumb {
  .breadcrumb {
    margin: 30px 0 40px 0;
    padding: 0;
    border: 0;
    background-color: transparent;

    .breadcrumb-item {
      margin: auto 0;
    }

    a {
      font-size: 20px;
    }

    svg {
      margin-right: 5px;
    }
  }
}

.admin-quick-start {
  h1 {
    font-family: $montserrat;
    font-weight: 700;
    line-height: 52px;
    margin-bottom: 30px;
  }

  h3 {
    font-family: $montserrat;
    font-weight: 600;
    color: $blue;
    margin-bottom: 30px;
  }

  h5 {
    font-weight: 400;
    line-height: 28px;
  }

  hr {
    margin: 60px 0 !important;
  }

  .screenshot {
    border: 1px solid $bordergray;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .topics {
    margin-top: 60px;
    background-color: $lightgray;
    border-radius: 10px;
    padding: 20px;

    .font-weight-bold {
      margin-right: 10px;
    }

    .pipe {
      padding: 0 10px;
      color: $gray;
    }
  }

  @media (max-width: $lg-width) {
    .illustration {
      width: 60%;
      height: auto;
    }
  }

    @media (max-width: $sm-width) {
    h1 {
      font-size: 32px;
      line-height: 42px;
    }

    .illustration {
      width: 100%;
      height: auto;
    }
  }
}
</style>
